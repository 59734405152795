<script lang="ts">
	import { COLS, ROWS } from "../../utils";

	import { Tile } from "../board";
import GameIcon from "../GameIcon.svelte";
	export let visible: boolean;
</script>

<h3>Instrucciones sobre cómo jugar </h3>

<div>Intenta resolver el rompecabezas de Wordle en {ROWS} intentos.</div>
<div>Tu suposición debe consistir en una palabra válida de {COLS} letras. Presiona Enter para enviar tu suposición.</div> 
<div>Después de cada intento, un color indicará la precisión de tu suposición en comparación con la palabra objetivo (consulta la imagen de ejemplo).</div>
<div class:complete={visible} class="examples">
	<div><strong>ver imagen de ejemplo</strong></div>
	<div class="row">
		<Tile value="B" state="🟩" />
		<Tile value="A" state="🔳" />
		<Tile value="C" state="🔳" />
		<Tile value="A" state="🔳" />
		<Tile value="N" state="🔳" />
	</div>
	<div>La letra <strong>B</strong> está presente en la palabra y está colocada correctamente. </div>
	<div class="row">
		<Tile value="D" state="🔳" />
		<Tile value="A" state="🟨" />
		<Tile value="T" state="🔳" />
		<Tile value="A" state="🔳" />
		<Tile value="R" state="🔳" />
	</div>
	<div>La <strong>A</strong> forma parte de la palabra pero no está en la posición correcta. </div>
	<div class="row">
		<Tile value="E" state="🔳" />
		<Tile value="S" state="🔳" />
		<Tile value="N" state="🔳" />
		<Tile value="O" state="⬛" />
		<Tile value="B" state="🔳" />
	</div>
	<div>La letra <strong>O</strong> no forma parte de la palabra en absoluto.</div>
</div>
<div>
	Experimenta posibilidades infinitas con las últimas características y modos que te permiten jugar con un número infinito de palabras. Simplemente cambia al modo infinito para disfrutar de juego ilimitado.
	<br>
	<br>Descubre más características explorando el menú de ajustes.
</div>

<style lang="scss">
	div {
		margin: 14px 0;
	}
	.examples {
		border-top: 1px solid var(--border-primary);
		border-bottom: 1px solid var(--border-primary);
		:global(.row > *) {
			height: 100%;
			aspect-ratio: 1;
		}
		&:not(.complete) :global(.row .back) {
			transition-delay: 0.3s;
		}
	}
	.row {
		height: 48px;
		display: flex;
		gap: 10px;
	}
</style>
