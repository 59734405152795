<script lang="ts">
	import { createEventDispatcher, onDestroy } from "svelte/internal";
	import { letterStates, mode } from "../../stores";
	import { COLS, keys } from "../../utils";
	import Key from "./Key.svelte";

	export let value = "";
	export let disabled = false;
	let preventChange = true;

	const dispatch = createEventDispatcher();

	function appendValue(char: string) {
		if (!disabled && value.length < COLS) {
			dispatch("keystroke", char);
			value += char;
		}
	}
	function backspaceValue() {
		if (!disabled) {
			value = value.slice(0, value.length - 1);
		}
	}
	function handleKeystroke(e: KeyboardEvent) {
		if (!disabled && !e.ctrlKey && !e.altKey) {
			if (e.key && /^[a-z]$/.test(e.key.toLowerCase())) {
				return appendValue(e.key.toLowerCase());
			}
			if (e.key === "Backspace") return backspaceValue();

			if (e.key === "Enter") return dispatch("submitWord");
		}
		if (e.key === "Escape") dispatch("esc");
	}

	// Ensure keys change on load instead of loading their state color & change the color of all the keys to neutral, then to their correct color on mode change
	const unsub = mode.subscribe(() => {
		preventChange = true;
		setTimeout(() => (preventChange = false), 200);
	});
	onDestroy(unsub);
</script>

<svelte:body on:keydown={handleKeystroke} />

<div class:preventChange class="keyboard">
	<div class="row">
		{#each keys[0] as letter}
			<Key
				{letter}
				on:keystroke={(e) => appendValue(e.detail)}
				state={$letterStates[letter]}
			/>
		{/each}
	</div>
	<div class="row">
		{#each keys[1] as letter}
			<Key
				{letter}
				on:keystroke={(e) => appendValue(e.detail)}
				state={$letterStates[letter]}
			/>
		{/each}
	</div>
	<div class="row">
		<Key letter="Ingresar" on:keystroke={() => !disabled && dispatch("submitWord")} />
		{#each keys[2] as letter}
			<Key
				{letter}
				on:keystroke={(e) => appendValue(e.detail)}
				state={$letterStates[letter]}
			/>
		{/each}
		<Key letter="" on:keystroke={backspaceValue}>

			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
			  </svg>
			  
		</Key>
	</div>
</div>

<style>
	.keyboard {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 2px;
	}
	.row {
		touch-action: manipulation;
		width: 100%;
		display: flex;
		margin: 0 auto 8px;
		padding: 0 8px;
		gap: 6px;
	}
	.row:nth-of-type(2) {
		padding: 0 30px;
	}
	svg {
		/* fill: var(--fg-primary); */
		width: 24px;
	}
</style>
