import seedrandom from "seedrandom";
import { GameMode, ms } from "./enums";
import wordList from "./words_5";
export const ROWS = 6;
export const COLS = 5;
export const words = Object.assign(Object.assign({}, wordList), { contains: (word) => {
        return wordList.words.includes(word) || wordList.valid.includes(word);
    } });
export function checkHardMode(board, row) {
    for (let i = 0; i < COLS; ++i) {
        if (board.state[row - 1][i] === "🟩" && board.words[row - 1][i] !== board.words[row][i]) {
            return { pos: i, char: board.words[row - 1][i], type: "🟩" };
        }
    }
    for (let i = 0; i < COLS; ++i) {
        if (board.state[row - 1][i] === "🟨" && !board.words[row].includes(board.words[row - 1][i])) {
            return { pos: i, char: board.words[row - 1][i], type: "🟨" };
        }
    }
    return { pos: -1, char: "", type: "⬛" };
}
class Tile {
    constructor() {
        this.notSet = new Set();
    }
    not(char) {
        this.notSet.add(char);
    }
}
class WordData {
    constructor() {
        this.notSet = new Set();
        this.letterCounts = new Map();
        this.word = [];
        for (let col = 0; col < COLS; ++col) {
            this.word.push(new Tile());
        }
    }
    confirmCount(char) {
        let c = this.letterCounts.get(char);
        if (!c) {
            this.not(char);
        }
        else {
            c[1] = true;
        }
    }
    countConfirmed(char) {
        const val = this.letterCounts.get(char);
        return val ? val[1] : false;
    }
    setCount(char, count) {
        let c = this.letterCounts.get(char);
        if (!c) {
            this.letterCounts.set(char, [count, false]);
        }
        else {
            c[0] = count;
        }
    }
    incrementCount(char) {
        ++this.letterCounts.get(char)[0];
    }
    not(char) {
        this.notSet.add(char);
    }
    inGlobalNotList(char) {
        return this.notSet.has(char);
    }
    lettersNotAt(pos) {
        return new Set([...this.notSet, ...this.word[pos].notSet]);
    }
}
export function getRowData(n, board) {
    const wd = new WordData();
    for (let row = 0; row < n; ++row) {
        const occured = new Set();
        for (let col = 0; col < COLS; ++col) {
            const state = board.state[row][col];
            const char = board.words[row][col];
            if (state === "⬛") {
                wd.confirmCount(char);
                // if char isn't in the global not list add it to the not list for that position
                if (!wd.inGlobalNotList(char)) {
                    wd.word[col].not(char);
                }
                continue;
            }
            // If this isn't the first time this letter has occured in this row
            if (occured.has(char)) {
                wd.incrementCount(char);
            }
            else if (!wd.countConfirmed(char)) {
                occured.add(char);
                wd.setCount(char, 1);
            }
            if (state === "🟩") {
                wd.word[col].value = char;
            }
            else { // if (state === "🟨")
                wd.word[col].not(char);
            }
        }
    }
    let exp = "";
    for (let pos = 0; pos < wd.word.length; ++pos) {
        exp += wd.word[pos].value ? wd.word[pos].value : `[^${[...wd.lettersNotAt(pos)].join(" ")}]`;
    }
    return (word) => {
        if (new RegExp(exp).test(word)) {
            const chars = word.split("");
            for (const e of wd.letterCounts) {
                const occurences = countOccurences(chars, e[0]);
                if (!occurences || (e[1][1] && occurences !== e[1][0]))
                    return false;
            }
            return true;
        }
        return false;
    };
}
function countOccurences(arr, val) {
    return arr.reduce((count, v) => v === val ? count + 1 : count, 0);
}
export function getState(word, guess) {
    const charArr = word.split("");
    const result = Array(5).fill("⬛");
    for (let i = 0; i < word.length; ++i) {
        if (charArr[i] === guess.charAt(i)) {
            result[i] = "🟩";
            charArr[i] = "$";
        }
    }
    for (let i = 0; i < word.length; ++i) {
        const pos = charArr.indexOf(guess[i]);
        if (result[i] !== "🟩" && pos >= 0) {
            charArr[pos] = "$";
            result[i] = "🟨";
        }
    }
    return result;
}
export function contractNum(n) {
    switch (n % 10) {
        case 1: return `${n}st`;
        case 2: return `${n}nd`;
        case 3: return `${n}rd`;
        default: return `${n}th`;
    }
}
export const keys = ["qwertyuiop", "asdfghjkl", "zxcvbnm"];
export function newSeed(mode) {
    const now = Date.now();
    switch (mode) {
        case GameMode.daily:
            // Adds time zome offset to UTC time, calculates how many days that falls after 1/1/1970
            // and returns the unix time for the beginning of that day.
            return Date.UTC(1970, 0, 1 + Math.floor((now - (new Date().getTimezoneOffset() * ms.MINUTE)) / ms.DAY));
        case GameMode.hourly:
            return now - (now % ms.HOUR);
        // case GameMode.minutely:
        // 	return now - (now % ms.MINUTE);
        case GameMode.infinite:
            return now - (now % ms.SECOND);
    }
}
export const modeData = {
    default: GameMode.infinite,
    modes: [
        {
            name: "A diario",
            unit: ms.DAY,
            start: 1642370400000,
            seed: newSeed(GameMode.daily),
            historical: false,
            streak: true,
            useTimeZone: true,
        },
        {
            name: "Cada hora",
            unit: ms.HOUR,
            start: 1642528800000,
            seed: newSeed(GameMode.hourly),
            historical: false,
            icon: "m50,7h100v33c0,40 -35,40 -35,60c0,20 35,20 35,60v33h-100v-33c0,-40 35,-40 35,-60c0,-20 -35,-20 -35,-60z",
            streak: true,
        },
        {
            name: "Infinita",
            unit: ms.SECOND,
            start: 1642428600000,
            seed: newSeed(GameMode.infinite),
            historical: false,
            icon: "m7,100c0,-50 68,-50 93,0c25,50 93,50 93,0c0,-50 -68,-50 -93,0c-25,50 -93,50 -93,0z",
        },
        // {
        // 	name: "Minutely",
        // 	unit: ms.MINUTE,
        // 	start: 1642528800000,	// 18/01/2022 8:00pm
        // 	seed: newSeed(GameMode.minutely),
        // 	historical: false,
        // 	icon: "m7,200v-200l93,100l93,-100v200",
        // 	streak: true,
        // },
    ]
};
export function getWordNumber(mode) {
    return Math.round((modeData.modes[mode].seed - modeData.modes[mode].start) / modeData.modes[mode].unit) + 1;
}
export function seededRandomInt(min, max, seed) {
    const rng = seedrandom(`${seed}`);
    return Math.floor(min + (max - min) * rng());
}
export const DELAY_INCREMENT = 200;
export const PRAISE = [
    "Genius",
    "Magnificent",
    "Impressive",
    "Splendid",
    "Great",
    "Phew",
];
export function createNewGame(mode) {
    return {
        active: true,
        guesses: 0,
        time: modeData.modes[mode].seed,
        wordNumber: getWordNumber(mode),
        validHard: true,
        board: {
            words: Array(ROWS).fill(""),
            state: Array.from({ length: ROWS }, () => (Array(COLS).fill("🔳")))
        },
    };
}
export function createDefaultSettings() {
    return {
        hard: new Array(modeData.modes.length).map(() => false),
        dark: false,
        colorblind: false,
        tutorial: 3,
    };
}
export function createDefaultStats(mode) {
    const stats = {
        played: 0,
        lastGame: 0,
        guesses: {
            fail: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        }
    };
    if (!modeData.modes[mode].streak)
        return stats;
    return Object.assign(Object.assign({}, stats), { streak: 0, maxStreak: 0 });
}
;
export function createLetterStates() {
    return {
        a: "🔳",
        b: "🔳",
        c: "🔳",
        d: "🔳",
        e: "🔳",
        f: "🔳",
        g: "🔳",
        h: "🔳",
        i: "🔳",
        j: "🔳",
        k: "🔳",
        l: "🔳",
        m: "🔳",
        n: "🔳",
        o: "🔳",
        p: "🔳",
        q: "🔳",
        r: "🔳",
        s: "🔳",
        t: "🔳",
        u: "🔳",
        v: "🔳",
        w: "🔳",
        x: "🔳",
        y: "🔳",
        z: "🔳",
    };
}
export function timeRemaining(m) {
    if (m.useTimeZone) {
        return m.unit - (Date.now() - (m.seed + new Date().getTimezoneOffset() * ms.MINUTE));
    }
    return m.unit - (Date.now() - m.seed);
}
export function failed(s) {
    return !(s.active || (s.guesses > 0 && s.board.state[s.guesses - 1].join("") === "🟩".repeat(COLS)));
}
